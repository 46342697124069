<template>
  <v-container>
    <v-row
      no-gutters
      class="mt-6 mb-6"
    >
      <v-col
        xl="2"
        lg="2"
        md="4"
        sm="4"
        cols="12"
      >
        <v-sheet
          id="categories"
          elevation="1"
          :class="categoryClass"
          :rounded="isRounded"
        >
          <v-menu
            v-model="categoryMenu"
            :close-on-content-click="false"
            offset-y
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                label="Categories"
                dense
                single-line
                solo
                flat
                hide-details="true"
                v-on="on"
                readonly
                :class="categoryHasSelectedOptions"
              >
                <v-icon
                  slot="prepend"
                  color="#006FFF"
                >
                  mdi-briefcase-variant-outline
                </v-icon>
              </v-text-field>
            </template>
            <CategoryOptions
              @hideCategoryMenu="hideCategoryMenu"
            />
          </v-menu>
          <v-overlay
            :value="categoryMenu"
          ></v-overlay>
        </v-sheet>
      </v-col>
      <v-col
        xl="3"
        lg="3"
        md="8"
        sm="8"
        cols="12"
      >
        <v-sheet
          id="locations"
          elevation="1"
          class="pa-4"
        >
          <v-select
            v-model="selectedLocations"
            :items="locations"
            clearable
            label="Locations"
            flat
            dense
            hide-details
            solo
            multiple
          >
          <template v-slot:selection="{item, index}">
            <span
              v-if="selectedLocations.length < 2"
              class="text-no-wrap text-caption overflow-hidden"
            >
              {{ item }}
            </span>
            <span
              v-if="index === 1"
              class="text-caption"
            >
              ({{ selectedLocations.length }} locations selected)
            </span>
          </template>
            <v-icon
              slot="prepend"
              color="#006FFF"
            >
              mdi-map-marker-outline
            </v-icon>
          </v-select>
        </v-sheet>
      </v-col>
      <v-col
        xl="2"
        lg="2"
        md="4"
        sm="4"
        cols="12"
      >
        <v-sheet
          id="mileage"
          elevation="1"
          class="pa-4"
        >
          <v-menu
            v-model="mileageMenu"
            :close-on-content-click="false"
            offset-y
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                label="Mileage"
                dense
                single-line
                solo
                flat
                hide-details="true"
                v-on="on"
                readonly
                :class="mileageHasSelectedOptions"
              >
                <v-icon
                  slot="prepend"
                  color="#006FFF"
                >
                  mdi-selection-marker
                </v-icon>
              </v-text-field>
            </template>
            <MileageOptions
              @hideMileageMenu="hideMileageMenu"
            />
          </v-menu>
          <v-overlay
            :value="mileageMenu"
          ></v-overlay>
        </v-sheet>
      </v-col>
      <v-col
        xl="4"
        lg="4"
        md="6"
        sm="6"
        cols="12"
      >
        <v-sheet
          elevation="1"
          class="pa-4"
        >
          <v-text-field
            v-model="keywords"
            clearable
            label="Keywords"
            flat
            dense
            hide-details
            solo
          >
            <v-icon
              slot="prepend"
              color="#006FFF"
            >
              mdi-magnify
            </v-icon>
          </v-text-field>
        </v-sheet>
      </v-col>
      <v-col
        xl="1"
        lg="1"
        md="2"
        sm="2"
        cols="12"
      >
        <v-sheet
          elevation="1"
          :class="findJobClass"
          height="100%"
          :rounded="isRounded"
        >
          <v-btn
            depressed
            color="#3B63FF"
            block
            dark
            height="70px"
            :class="findJobClass"
            :tile="isTiled"
            @click="filterJobs"
            :loading="loading"
          >
            Find Job
          </v-btn>
        </v-sheet>
      </v-col>
    </v-row>
    <v-row
      class="mr-0 mt-n5"
    >
      <v-spacer></v-spacer>
      <div class="clearFilters mr-1">
        <span @click="resetAllFilters"><u>clear filters</u></span>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import CategoryOptions from './CategoryOptions.vue';
import MileageOptions from './MileageOptions.vue';

export default {
  name: 'FiltersBar',
  components: {
    CategoryOptions,
    MileageOptions,
  },
  data() {
    return {
      longPress: false,
      selectedOptions: [],
      categoryMenu: false,
      mileageMenu: false,
    };
  },
  computed: {
    isRounded() {
      const breakpoint = this.$vuetify.breakpoint.name;
      if (breakpoint === 'lg' || breakpoint === 'xl') {
        return true;
      }

      return false;
    },
    isTiled() {
      const breakpoint = this.$vuetify.breakpoint.name;
      if (breakpoint === 'lg' || breakpoint === 'xl') {
        return false;
      }

      return true;
    },
    categoryClass() {
      const breakpoint = this.$vuetify.breakpoint.name;
      if (breakpoint === 'xl' || breakpoint === 'lg') {
        return 'rounded-lg rounded-r-0 pa-4';
      }

      return 'pa-4';
    },
    findJobClass() {
      const breakpoint = this.$vuetify.breakpoint.name;
      if (breakpoint === 'xl' || breakpoint === 'lg') {
        return 'rounded-lg rounded-l-0 pa-0';
      }

      return 'pa-0';
    },
    locations() {
      return this.$store.state.availableLocations;
    },
    selectedLocations: {
      get() {
        return this.$store.state.filters.locations;
      },
      set(newValue) {
        this.$store.commit('updateFiltersWithSelectedLocations', newValue);
      },
    },
    keywords: {
      get() {
        return this.$store.state.keywords.join(' ');
      },
      set(newValue) {
        let searchTerms = [];
        if (newValue != null) {
          searchTerms = newValue.split(' ');
        }
        this.$store.commit('updateKeywords', searchTerms);
      },
    },
    loading() {
      return this.$store.state.findJobLoader;
    },
    categoryHasSelectedOptions() {
      const options = this.$store.state.filters.categories.length;
      if (options > 0 && options < 16) {
        return 'font-weight-bold';
      }

      return '';
    },
    mileageHasSelectedOptions() {
      const options = this.$store.state.filters.gmaps;
      let classToReturn = '';
      Object.entries(options).forEach((entry) => {
        const [key, value] = entry;
        switch (key) {
          case 'mode':
            if (value !== 0) {
              classToReturn = 'font-weight-bold';
            }
            break;
          case 'distance':
            if (value !== null) {
              classToReturn = 'font-weight-bold';
            }
            break;
          case 'duration':
            if (value !== null) {
              classToReturn = 'font-weight-bold';
            }
            break;
          case 'avoid':
            if (value !== null) {
              classToReturn = 'font-weight-bold';
            }
            break;
          case 'departue':
            if (value !== null) {
              classToReturn = 'font-weight-bold';
            }
            break;
          default:
            break;
        }
      });

      return classToReturn;
    },
  },
  methods: {
    logToConsole(val) {
      console.log(val);
    },
    filterJobs() {
      this.$store.commit('updateJobListingsFromFilters');
      const { gmaps } = this.$store.state.filters;
      if (gmaps.distance != null || gmaps.duration != null) {
        this.$store.commit('updateFindJobLoader', true);
        if (navigator.geolocation) {
          this.$store.dispatch('filterByMileage');
        }
      }

      this.trackMatomoSearchQuery();
    },
    hideCategoryMenu() {
      this.categoryMenu = false;
    },
    hideMileageMenu() {
      this.mileageMenu = false;
    },
    trackMatomoSearchQuery() {
      const searchOptions = [];
      if (this.keywords !== '') {
        searchOptions.push(this.$store.state.keywords.join(' '));
      }
      if (this.$store.state.filters.locations.length > 0) {
        searchOptions.push(this.$store.state.filters.locations.join(','));
      }
      if (this.$store.state.filters.categories.length === this.$store.state.categoryOptions.length) {
        searchOptions.push('All Categories');
      } else {
        searchOptions.push(this.$store.state.filters.categories.join(','));
      }
      // Mileage Options
      if (this.$store.state.filters.gmaps.distance != null) {
        searchOptions.push(`distance: ${this.$store.state.filters.gmaps.distance}`);
      }
      if (this.$store.state.filters.gmaps.duration != null) {
        searchOptions.push(`duration: ${this.$store.state.filters.gmaps.duration}`);
      }
      if (this.$store.state.filters.gmaps.departure != null) {
        searchOptions.push(`departure: ${this.$store.state.filters.gmaps.departure}`);
      }

      switch (this.$store.state.filters.gmaps.avoid) {
        case 0:
          searchOptions.push('avoid: tolls');
          break;
        case 1:
          searchOptions.push('avoid: highways');
          break;
        case 2:
          searchOptions.push('avoid: ferries');
          break;
        default:
          break;
      }

      if (this.$store.state.filters.gmaps.distance != null || this.$store.state.filters.gmaps.duration != null) {
        switch (this.$store.state.filters.gmaps.mode) {
          case 0:
            searchOptions.push('mode: driving');
            break;
          case 1:
            searchOptions.push('mode: bicycling');
            break;
          case 2:
            searchOptions.push('mode: walking');
            break;
          default:
            break;
        }
      }

      if (searchOptions.length > 0) {
        this.$matomo.trackSiteSearch(searchOptions.join(), 'Search');
      }
    },
    resetAllFilters() {
      // Reset Selected Categories
      const options = this.$store.state.categoryOptions;
      this.$store.commit('resetSelectedCategories', options.map((category) => category.name));

      // Reset Selected Locations
      this.selectedLocations = [];

      // Reset Mileage Options
      this.$store.commit('resetSelectedGmapsOptions');
      this.$store.commit('setResetAll', true);

      // Reset Keywords
      this.$store.commit('updateKeywords', []);

      this.$store.commit('updateJobListingsFromFilters');
    },
  },
};
</script>

<style lang="scss" scoped>
#app>div.v-menu__content[role="menu"] {
  visibility: visible;
  border-radius: 25px;
}
#locations {
  ::v-deep input {
    display: none;
  }
}
.clearFilters {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  cursor: pointer;
}
.v-tooltip__content {
  background: none;
  opacity: 1 !important;
}
</style>
