<template>
  <!-- style="max-height: calc(100vh - 226px)" -->
  <div>
    <v-row v-if="initialLoading">
      <v-col
        v-for="n in 10"
        :key="n"
        :xl="6"
        :lg="6"
        :md="6"
        :sm="6"
        :cols="12"
      >
        <v-skeleton-loader
          class="mx-auto"
          type="article, actions"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row class="overflow-y-auto" v-if="initialLoading === false">
      <v-col v-if="listings.length === 0">
        <div>
          <p class="text-sm-center text-lg-h5 text-h6">There are no jobs available matching your selection.</p>
          <p class="text-sm-center">Please try the search with different filters or clearing the existing ones.</p>
        </div>
      </v-col>
      <v-col
        :xl="getFlexed('xl')"
        :lg="getFlexed('lg')"
        :md="getFlexed('md')"
        :sm="getFlexed('sm')"
        :cols=flexListing.cols
        v-if="loading === false && listings.length > 0"
      >
        <v-skeleton-loader
          class="mx-auto"
          type="article, actions"
          v-if="loading"
          elevation="1"
        ></v-skeleton-loader>
        <v-card
          elevation="1"
          dark
          color="pink darken-2"
          :ripple="{ class: 'rippleColor' }"
          v-if="loading === false"
          @click.prevent="openSubscribeWindow"
        >
          <v-card-title
            style="word-break: break-word; font-weight: 400;"
            class="text-subtitle-1 text-lg-h6"
          >
            Subscribe to job alerts
          </v-card-title>
          <!-- eslint-disable-next-line max-len -->
          <v-card-text>Would you like to be alerted when a new job is posted? By subscribing you can be alerted via phone, email, or both whenever a new job is added or updated. Be among the first to apply!</v-card-text>
          <v-card-actions></v-card-actions>
        </v-card>
      </v-col>
      <v-col
        v-for="job in listings"
        :key="job.id"
        :xl="getFlexed('xl')"
        :lg="getFlexed('lg')"
        :md="getFlexed('md')"
        :sm="getFlexed('sm')"
        :cols=flexListing.cols
      >
        <v-skeleton-loader
          class="mx-auto"
          type="article, actions"
          v-if="loading"
        ></v-skeleton-loader>
        <v-card
          :id="job.id"
          :href="createJobHref(job.id)"
          elevation="1"
          v-on:click.prevent="openJob(job.id), activeJob=job.id"
          :outlined="activeJob === job.id"
          :class="{ 'selectedItem' : activeJob === job.id }"
          :ripple="{ class: 'rippleColor' }"
          v-if="loading === false"
        >
          <v-card-title
            style="word-break: break-word; font-weight: 400;"
            class="text-subtitle-1 text-lg-h6"
          >{{ job.title }}</v-card-title>
          <v-card-text
            class="three-lines mb-4 pb-0"
          >{{ job.briefDescription }}</v-card-text>
          <v-card-actions class="px-4 py-2">
            <v-row
              dense
              v-if="stackedLabels() == true"
            >
              <v-col
                cols="12"
              >
                <v-btn
                  color="gray"
                  x-small
                  depressed
                  v-on:click.stop.prevent="filterBySelectedCategory(job.category)"
                  class="hover-color"
                >
                  <v-icon left>
                    mdi-briefcase-variant-outline
                  </v-icon>
                  {{job.category}}
                </v-btn>
              </v-col>
              <v-col
                cols="12"
              >
                <v-btn
                  color="gray"
                  x-small
                  depressed
                  v-on:click.stop.prevent="filterBySelectedLocation(job.location)"
                >
                  <v-icon left>
                    mdi-map-marker-outline
                  </v-icon>
                  {{job.location}}
                </v-btn>
              </v-col>
            </v-row>
            <!-- TODO: Move buttons to their own component and eliminate duplication -->
            <v-btn
              color="gray"
              x-small
              depressed
              v-on:click.stop.prevent="filterBySelectedCategory(job.category)"
              class="hover-color"
              v-if="stackedLabels() == false"
            >
              <v-icon left>
                mdi-briefcase-variant-outline
              </v-icon>
              {{job.category}}
            </v-btn>
            <v-btn
              color="gray"
              x-small
              depressed
              v-on:click.stop.prevent="filterBySelectedLocation(job.location)"
              v-if="stackedLabels() == false"
            >
              <v-icon left>
                mdi-map-marker-outline
              </v-icon>
              {{job.location}}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  name: 'JobListings',
  props: {
    InfoVisibility: Boolean,
  },
  data() {
    return {
      jobId: null,
      activeJob: null,
      flexListing: {
        xl: 12,
        lg: 12,
        md: 12,
        sm: 12,
        cols: 12,
      },
    };
  },
  computed: {
    getFlexed() {
      return (breakpoint) => {
        if (breakpoint === 'xl') {
          if (this.InfoVisibility === false) {
            return 6;
          }
          return 12;
        }

        if (breakpoint === 'lg') {
          if (this.InfoVisibility === false) {
            return 6;
          }
          return 12;
        }

        if (breakpoint === 'md' || breakpoint === 'sm') {
          if (this.InfoVisibility === false) {
            return 6;
          }
          return 12;
        }

        return 12;
      };
    },
    listings() {
      return this.$store.state.filteredJobListings;
    },
    loading() {
      return this.$store.state.findJobLoader;
    },
    initialLoading() {
      return this.$store.state.initialLoad;
    },
    mobileBreakpoint() {
      return this.$vuetify.breakpoint.name;
    },
  },
  methods: {
    openSubscribeWindow() {
      const bp = this.$vuetify.breakpoint;
      if (bp.lgAndUp === true) {
        const jobAlerts = this.$router.resolve({ name: 'Job Alerts' });
        window.open(jobAlerts.href);
      } else {
        // const jobAlerts = this.$router.resolve({ name: 'Job Alerts Mobile' });
        // window.open(jobAlerts.href);
        this.$store.commit('updateDrawerBoolValue', true);
      }
    },
    openJob(jobId) {
      this.$router.push({ name: 'JobInfo', params: { job_id: jobId } });
      this.$emit('showJob', jobId);
    },
    filterBySelectedCategory(category) {
      this.$store.commit('updateFiltersWithSelectedCategory', [category]);
      this.$store.commit('updateJobListingsFromFilters');
    },
    filterBySelectedLocation(location) {
      this.$store.commit('updateFiltersWithSelectedLocations', [location]);
      this.$store.commit('updateJobListingsFromFilters');
    },
    stackedLabels() {
      if (this.mobileBreakpoint !== 'lg' && this.mobileBreakpoint !== 'xl') {
        return true;
      }
      return false;
    },
    createJobHref(id) {
      return `${process.env.VUE_APP_BASE}job/${id}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.three-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: normal;
}
.selectedItem {
  border-color: #1444FF !important;
}
.selectedItem::before {
  background-color: white !important;
}
.subscriptionCard {
  border-color: green !important;
}
.subscriptionCard::before {
  background-color: white !important;
}
</style>
