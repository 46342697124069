<template>
  <div>
    <HeaderBar />
    <v-dialog
      v-model="dialog"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title>Sorry</v-card-title>
        <v-card-text>
          <!-- eslint-disable-next-line max-len -->
          <div>You must select at least 1 category</div>
        </v-card-text>
        <v-card-actions style="background-color: #3B63FF;">
          <v-spacer></v-spacer>
          <v-btn
            class="blue--text text--darken-2 font-weight-bold mr-0"
            color="white"
            @click="dialog = false"
          >Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="categories">
      <v-card
        class="cat"
        color="white"
        elevation="1"
        width="600px"
        style="padding: 2em;"
      >
        <v-card-text class="cSeq">
          <p class="text-h5 text-center font-weight-bold">You're almost done!</p>
          <p class="text-center">Please choose the categories you would like to be alerted for:</p>
          <v-row>
            <v-col
              v-for="(item, index) in options"
              :key="index"
              cols="6"
              class="py-1"
            >
              <v-checkbox
                class="checkegories"
                v-model="categorySelections"
                :label="item.name"
                :value="item.name"
                hide-details
                dense
                color="info"
                style="padding-left: 6px;"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="mt-8">
          <v-spacer></v-spacer>
          <v-btn
            color="#3b63ff"
            depressed
            class="white--text"
            :loading="showLoading"
            @click="selectCategories"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
    <div class="successful">
      <!-- eslint-disable-next-line max-len -->
      <lottie-player class="lottie" src="./success.json" background="transparent" speed="2"></lottie-player>
      <h2 style="text-align: center; font-size: 3em;">Please check your messages</h2>
    </div>
    <v-container fill-height style="height: calc(100vh - 64px); opacity: 1">
      <v-row
        justify="center"
        align="center"
      >
        <v-col align-self="center" cols="6" md="4" lg="4" sm="6">
          <v-card
            width="500px"
            style="padding: 2em;"
            id="main_card"
            ref="form"
          >
            <div class="onStart">
              <!-- eslint-disable-next-line max-len -->
              <lottie-player src="./envelope.json"  background="transparent"  speed="1"  style="width: 300px; height: 300px;"  loop  autoplay></lottie-player>
            </div>
            <v-card-text class="sSeq">
              <p class="text-h5 text-center font-weight-bold">
                Signup for job alerts
              </p>
              <div class="text-center">
                <!-- eslint-disable-next-line max-len -->
                Get notified when new jobs are posted. We send daily alerts to your inbox and more frequent alerts via text messaging.
              </div>
              <br>
              <v-text-field
                v-model="name"
                ref="name"
                :rules="[rules.required]"
                label="Name"
                placeholder="John Doe"
              ></v-text-field>
              <v-text-field
                v-model="email"
                ref="email"
                :rules="[rules.email]"
                label="Email"
                placeholder="john.doe@gmail.com"
                suffix="optional"
              ></v-text-field>
              <v-text-field
                v-model="phone"
                ref="phone"
                :rules="[rules.phone]"
                label="Mobile Phone"
                placeholder="950-555-1212"
                suffix="optional"
              ></v-text-field>
            </v-card-text>
            <v-card-actions class="seq">
              <v-spacer></v-spacer>
              <v-btn
                color="#3b63ff"
                depressed
                class="white--text"
                @click="signup"
              >
                Sign Up!
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar
      v-model="snackbar"
      timeout="5000"
      color="error"
      text
    >
      You must enter an <strong>email</strong>, <strong>phone</strong>, or <strong>both</strong>.
      <template v-slot:action="{ attrs }">
        <v-btn
          color="black"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/CustomEase';
import { validationRules } from '@/mixins/validationRules';
import HeaderBar from './HeaderBar.vue';

export default {
  name: 'JobAlerts',
  mixins: [validationRules],
  components: {
    HeaderBar,
  },
  data() {
    return {
      marker: null,
      categorySelections: [],
      name: '',
      email: '',
      phone: '',
      snackbar: false,
      validationError: false,
      jobAlert: {
        name: null,
        email: null,
        phone: null,
        emailEnabled: false,
        smsEnabled: false,
        categories: [],
      },
      showLoading: false,
      dialog: false,
    };
  },
  computed: {
    options() {
      return this.$store.state.categoryOptions;
    },
    form() {
      return {
        name: this.name,
        email: this.email,
        phone: this.phone,
      };
    },
    jobAlertTrue() {
      return this.$store.state.jobAlertTrue;
    },
  },
  created() {
    document.title = 'Subscribe to Job Alerts';
    this.$store.dispatch('getCategoryOptions');
  },
  methods: {
    selectCategories() {
      if (this.categorySelections.length === 0) {
        this.dialog = true;
        return;
      }
      this.showLoading = true;
      this.jobAlert.name = this.name;
      this.jobAlert.email = this.email;
      this.jobAlert.phone = this.phone;
      this.jobAlert.categories = this.categorySelections.map((cat) => ({ categoryName: cat }));

      this.$store.commit('updateJobAlertObject', this.jobAlert);

      this.$store.dispatch('createJobAlert').then(() => {
        if (this.jobAlertTrue) {
          this.$matomo.trackEvent('Subscription', 'Alerts', 'Subscribed to job alerts!');
          gsap.registerPlugin(CustomEase);

          // eslint-disable-next-line new-cap
          const tl = new gsap.timeline({ defaults: { ease: 'circ.easeOut' } });
          // eslint-disable-next-line no-unused-vars
          const player = document.querySelector('.lottie');

          tl.paused(true);
          tl.to('.cSeq', { y: 40, opacity: 0, stagger: 0.05 });
          tl.to('.v-card.cat', { opacity: 0 }, '-=.4');
          tl.to('.successful', { opacity: 1, duration: 2, zIndex: 3 }, '+=1');

          tl.play();
          setTimeout(() => {
            player.play();
          }, 1000);
        } else {
          this.showLoading = false;
          console.error('There was an error creating a new job alert');
        }
      });
    },
    signup() {
      if (!this.form.email && !this.form.phone) {
        this.snackbar = true;
        return;
      }

      if (!this.$refs.name.validate()) {
        this.$refs.name.validate(true);
        return;
      }

      if (!this.$refs.email.validate()) {
        this.$refs.email.validate(true);
        return;
      }

      if (!this.$refs.phone.validate()) {
        this.$refs.phone.validate(true);
        return;
      }

      this.phone = this.phone.replace(this.phoneRegex, '$1$2$3');

      gsap.registerPlugin(CustomEase);

      // eslint-disable-next-line new-cap
      const tl = new gsap.timeline({ defaults: { ease: 'circ.easeOut' } });
      // eslint-disable-next-line no-unused-vars
      const player = document.querySelector('.lottie');

      tl.paused(true);
      tl.to('.sSeq', { y: 40, opacity: 0, stagger: 0.05 });
      tl.to('.v-card#main_card', { opacity: 0, zIndex: 1 }, '-=.4');
      tl.to('.categories', { opacity: 1, duration: 2, zIndex: 2 }, '+=1');

      tl.play();
    },
  },
};
</script>

<style lang="scss" scoped>
.categories {
  ::v-deep i.v-icon {
    font-size: 23px;
  }
}

.categories {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}

.successful {
  position: absolute;
  width: 300px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}

.onStart {
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hidden {
  display: none;
}
</style>
