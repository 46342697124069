/* eslint-disable import/prefer-default-export */
export const validationRules = {
  data() {
    return {
      phoneRegex: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
      // eslint-disable-next-line max-len
      emailRegex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      rules: {
        required: (value) => !!value || 'Required',
        email: (value) => {
          if (value === '' || value === null) {
            return true;
          }
          return this.emailRegex.test(value) || 'Invalid e-mail';
        },
        phone: (value) => {
          if (value === '' || value === null) {
            return true;
          }
          return this.phoneRegex.test(value) || 'Invalid phone number';
        },
      },
    };
  },
};
