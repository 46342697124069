<template>
  <v-main>
    <HeaderBar />
    <v-row class="mt-16" justify="center">
      <v-card>
        <v-card-text>
          <p style="word-break: break-word; font-weight: 400;">Please wait while your subscription is validated</p>
          <div class="text-center">
            <v-progress-circular
              indeterminate
              color="green"
            ></v-progress-circular>
          </div>
        </v-card-text>
      </v-card>
    </v-row>
  </v-main>
</template>

<script>
import HeaderBar from './HeaderBar.vue';

export default {
  name: 'ValidateSubscription',
  components: {
    HeaderBar,
  },
  data() {
    return {
      temp: null,
    };
  },
  computed: {
    subscriptionId() {
      return this.$route.params.subscriptionId;
    },
  },
  created() {
    this.$store.commit('setJobAlertSubscriptionId', this.subscriptionId);
    this.$store.dispatch('validateSubscription');
  },
};
</script>

<style lang="scss" scoped>

</style>
