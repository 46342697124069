import Vue from 'vue';
import VueRouter from 'vue-router';
import HomePage from '../components/HomePage.vue';
import NotFound from '../components/NotFound.vue';
import JobAlerts from '../components/JobAlerts.vue';
import Unsubscribe from '../components/UnsubscribeUser.vue';
import JobAlertsMobile from '../components/JobAlertsMobile.vue';
import GreatSuccess from '../components/GreatSuccess.vue';
import ValidateSubscription from '../components/ValidateSubscription.vue';
import UhOh from '../components/UhOh.vue';

Vue.use(VueRouter);

const router = new VueRouter({
  routes: [
    {
      path: '/',
      name: 'Home',
      component: HomePage,
    },
    {
      path: '/job/:job_id',
      name: 'JobInfo',
      component: HomePage,
      beforeEnter(to, from, next) {
        const isValidId = Number.isInteger(Number(to.params.job_id));
        next(isValidId);
      },
    },
    {
      path: '/signup-mobile',
      name: 'Job Alerts Mobile',
      component: JobAlertsMobile,
    },
    {
      path: '/signup',
      name: 'Job Alerts',
      component: JobAlerts,
    },
    {
      path: '/success',
      name: 'Success',
      component: GreatSuccess,
    },
    {
      path: '/unsubscribe/:subscriptionId',
      name: 'Unsubscribe',
      component: Unsubscribe,
    },
    {
      path: '/validate/:subscriptionId',
      name: 'Validate',
      component: ValidateSubscription,
    },
    {
      path: '/uhoh',
      name: 'UhOh',
      component: UhOh,
    },
    {
      path: '/search',
      name: 'LandingSearch',
      component: HomePage,
      props: (route) => ({
        query: [
          route.query.location,
          route.query.keywords,
        ],
      }),
    },
    {
      path: '*',
      name: 'NotFound',
      component: NotFound,
    },
  ],

  mode: 'history',
});

export default router;
