<template>
  <v-main>
    <HeaderBar />
    <v-container id="forohfor">
      <v-row class="mt-16">
        <v-col>
          <p class="notfound text-h2 text-sm-h1 text-lg-h1 text-center">
            Page Not Found
          </p>
          <p class="text-h5 text-sm-h5 text-lg-h5 text-center">
            We're sorry, but that page or job post could not be found
          </p>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="mt-16">
      <v-row align="stretch">
        <v-col cols="12" md="4" class="px-8" style="z-index: 1">
          <v-card
            shaped
            flat
            height="100%"
          >
            <v-card-text>
              <p class="text-h4 text--primary">Get in touch</p>
              <div class="text--primary">
                If you've reached this page and think it's an error. Please
                give us a call or send an email by clicking the link below:
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn
                text
                color="#006FFF"
                href="https://www.landrumhr.com/contact-us"
              >
                Contact Us
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" md="4" class="px-8" style="z-index: 1">
          <v-card
            shaped
            flat
            height="100%"
          >
            <v-card-text>
              <p class="text-h4 text--primary">Why am I here</p>
              <div class="text--primary">
                If you arrived here from another webpage it's possible that
                the information is old or that the job has expired. If you like
                more information please click 'Contact Us' to speak with a representative.
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="4" class="px-8" style="z-index: 1">
          <v-card
            shaped
            flat
            height="100%"
          >
            <v-card-text>
              <p class="text-h4 text--primary">Available jobs</p>
              <div class="text--primary">
                We have plenty of available jobs and hopefully you'll
                find one that's right for you. Please click 'Find Job'
                at the top to search additional positions.
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <meta name="robots" content="noindex"/>
  </v-main>
</template>

<script>
import HeaderBar from './HeaderBar.vue';

export default {
  name: 'NotFound',
  components: {
    HeaderBar,
  },
  data() {
    return {
      marker: null,
    };
  },
  computed: {
    breakpoint() {
      const breakpoint = this.$vuetify.breakpoint.name;
      if (breakpoint === 'lg' || breakpoint === 'xl') {
        return true;
      }
      return false;
    },
  },
  created() {
    document.title = 'Not Found';
  },
};
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@600&display=swap');

#forohfor {
  font-family: 'Open Sans', sans-serif;
  background-color: #F3F3F3;
  height: 50vh;
}
.notfound {
  font-family: 'Fira Sans', sans-serif !important;
  color: #006FFF;
}
</style>
