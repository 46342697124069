<template>
  <v-card
    class="ma-auto"
    width="378px"
    rounded="xl"
    elevation="4"
  >
    <v-dialog
      v-model="dialog"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title>We're Sorry</v-card-title>
        <v-card-text>
          <!-- eslint-disable-next-line max-len -->
          <div>Please enter the zip code or the address where you will be residing. Searching by address will be more exact than zip code.</div>
          <br>
          <div>[This information is not stored/collected]</div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="blue--text text--darken-2 font-weight-bold mr-0"
            color="white"
            @click="dialog = false"
          >Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog2"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title>Almost there</v-card-title>
        <v-card-text>
          <!-- eslint-disable-next-line max-len -->
          <div>Please enter a distance, travel time, or both</div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="blue--text text--darken-2 font-weight-bold mr-0"
            color="white"
            @click="dialog2 = false"
          >Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card-text style="color: black;">
      <div class="font-weight-bold">Location</div>
      <v-text-field
        label="Address / Zip Code"
        hint='Using an address will be more accurate'
        outlined
        clearable
        dense
        single-line
        v-model="gmaps.zip"
      ></v-text-field>
      <div class="font-weight-bold">Distance</div>
      <v-text-field
        label="25"
        outlined
        clearable
        dense
        single-line
        suffix="miles"
        v-model="gmaps.distance"
      ></v-text-field>
      <div class="font-weight-bold">Departure Time</div>
      <v-menu
        ref="menu"
        v-model="timeMenu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            label="7:30 AM"
            outlined
            clearable
            dense
            single-line
            v-model="civiTime"
            v-bind="attrs"
            v-on="on"
            readonly
          ></v-text-field>
        </template>
        <v-time-picker
          v-if="timeMenu"
          v-model="gmaps.departure"
          full-width
          no-title
          @click:minute="$refs.menu.save(civiTime)"
        ></v-time-picker>
      </v-menu>
      <div class="font-weight-bold">Travel Time</div>
      <v-text-field
        label="40"
        outlined
        clearable
        dense
        single-line
        suffix="minutes"
        v-model="gmaps.duration"
      ></v-text-field>
      <div class="font-weight-bold">Avoid</div>
      <v-row
        class="px-4 mt-1 mb-4"
      >
        <v-btn-toggle
          v-model="gmaps.avoid"
          class="flex-grow-1"
          id="modeToggle"
          style="max-width: 100%;"
        >
          <v-tooltip top color="success" :disabled="displayBtnCaptions">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="flex-grow-1"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-currency-usd</v-icon>
                <div v-if="displayBtnCaptions == true">Tolls</div>
              </v-btn>
            </template>
            <span>Tolls</span>
          </v-tooltip>
          <v-tooltip top color="success" :disabled="displayBtnCaptions">
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="flex-grow-1" v-bind="attrs" v-on="on">
                <v-icon>mdi-road-variant</v-icon>
                <div v-if="displayBtnCaptions == true">Highways</div>
              </v-btn>
            </template>
            <span>Highways</span>
          </v-tooltip>
          <v-tooltip top color="success" :disabled="displayBtnCaptions">
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="flex-grow-1" v-bind="attrs" v-on="on">
                <v-icon>mdi-ferry</v-icon>
                <div v-if="displayBtnCaptions == true">Ferries</div>
              </v-btn>
            </template>
            <span>Ferries</span>
          </v-tooltip>
        </v-btn-toggle>
      </v-row>
      <div class="font-weight-bold">Transportation Type</div>
      <v-row
        class="px-4 mt-1 mb-3"
      >
        <v-btn-toggle
          v-model="gmaps.mode"
          class="flex-grow-1"
          id="modeToggle"
          style="max-width: 100%;"
          mandatory
        >
          <v-tooltip top color="success" :disabled="displayBtnCaptions">
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="flex-grow-1" v-bind="attrs" v-on="on">
                <v-icon>mdi-car</v-icon>
                <div v-if="displayBtnCaptions == true">Vehicle</div>
              </v-btn>
            </template>
            <span>Automotive</span>
          </v-tooltip>
          <v-tooltip top color="success" :disabled="displayBtnCaptions">
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="flex-grow-1" v-bind="attrs" v-on="on">
                <v-icon>mdi-bike</v-icon>
                <div v-if="displayBtnCaptions == true">Bicycling</div>
              </v-btn>
            </template>
            <span>Bicycling</span>
          </v-tooltip>
          <v-tooltip top color="success" :disabled="displayBtnCaptions">
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="flex-grow-1" v-bind="attrs" v-on="on">
                <v-icon>mdi-walk</v-icon>
                <div v-if="displayBtnCaptions == true">Walking</div>
              </v-btn>
            </template>
            <span>Walking</span>
          </v-tooltip>
        </v-btn-toggle>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <div
        class="white--text text-decoration-underline"
        style="cursor: pointer"
        @click="resetFilters"
      >
        Reset all filters
      </div>
      <v-spacer></v-spacer>
      <v-btn
        depressed
        class="blue--text text--darken-2 font-weight-bold mr-0"
        color="white"
        @click="apply"
      >
        Apply
      </v-btn>
      <v-btn
        depressed
        class="blue--text text--darken-2 font-weight-bold mr-4"
        color="white"
        @click="cancel"
      >
        Cancel
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

export default {
  name: 'MileageOptions',
  data() {
    return {
      selectAll: true,
      selectedOptions: [],
      gmaps: {
        distance: null,
        duration: null,
        departure: null,
        origin: null,
        ids: null,
        avoid: null,
        mode: 0,
        zip: null,
      },
      gmapsReset: {},
      timeMenu: null,
      dialog: false,
      dialog2: false,
    };
  },
  created() {
    this.gmapsReset = { ...this.gmaps };
  },
  watch: {
    resetAll(newValue) {
      if (newValue === true) {
        this.resetFilters();
        this.$store.commit('setResetAll', false);
      }
    },
  },
  computed: {
    civiTime: {
      get() {
        const chosenTime = this.gmaps.departure;
        if (chosenTime === null) {
          return null;
        }
        const hours = chosenTime.split(':')[0];
        const minutes = chosenTime.split(':')[1];
        if (hours > 12) {
          return `${hours - 12}:${minutes} PM`;
        }

        return `${chosenTime} AM`;
      },
      set(value) {
        this.gmaps.departure = value;
      },
    },
    resetAll() {
      return this.$store.state.resetAll;
    },
    displayBtnCaptions() {
      const bn = this.$vuetify.breakpoint.name;
      if (bn !== 'lg' && bn !== 'xl') {
        return true;
      }

      return false;
    },
  },
  methods: {
    apply() {
      // if (navigator.geolocation) {
      //   this.updateCoords((origin) => {
      //     this.gmaps.origin = origin;
      //     this.commitGmaps();
      //   });
      // }
      if (this.isNullOrEmpty(this.gmaps.distance) && this.isNullOrEmpty(this.gmaps.duration)) {
        this.dialog2 = true;
        return;
      }

      if (this.updateOrigin(this.gmaps.zip)) {
        this.commitGmaps();
        this.$emit('hideMileageMenu');
      }
    },
    cancel() {
      this.$emit('hideMileageMenu');
    },
    resetFilters() {
      this.gmaps = { ...this.gmapsReset };
    },
    updateCoords(callback) {
      navigator.geolocation.getCurrentPosition((position) => {
        const ret = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        callback(ret);
      }, () => {
        // Track matomo search attempt if Geolocation permission has been denied
        this.$matomo.trackEvent('Error', 'Search', 'Geolocation permission was denied');
        this.resetFilters();
        this.dialog = true;
      });
    },
    updateOrigin(value) {
      if (value != null) {
        this.gmaps.origin = value;
        return true;
      }
      this.dialog = true;
      return false;
    },
    commitGmaps() {
      this.$store.commit('updateGmaps', JSON.parse(JSON.stringify(this.gmaps)));
    },
    isNullOrEmpty(value) {
      // todo: move to mixin or global method
      if (value == null || value === undefined || value.length === 0) {
        return true;
      }

      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card__actions {
  background-color: #3B63FF;
}
.v-card__actions > a {
  color: white;
  padding-left: 5px;
}
.growww {
  flex-grow: 1;
}
#modeToggle {
  ::v-deep .v-btn__content {
    flex-direction: column;
  }
}
</style>
